import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Grid, Group, Image, rem, Stack, Text, Title } from '@mantine/core';

import ChecklistMinimalistic from './ChecklistMinimalistic.svg';
import ShieldCheck from './ShieldCheck.svg';
import UsersGroupTwoRounded from './UsersGroupTwoRounded.svg';

export default function Trust(props) {
  const user = usePage().props?.auth?.user;

  return (
    <Box bg="gfdark.9" id="trust">
      <Container py={{ base: 50, md: 100 }} {...props}>
        <Stack align="center">
          <Title align={'center'} lh={1.2} fw={500} fz={rem(48)} lts={rem(-3)} c={'white'}>
            Trust & Transparency
          </Title>
          <Grid grow gutter={{ base: 50, md: 100 }} overflow="hidden" mt={{ base: 50, md: 100 }}>
            <Grid.Col span={{ md: 4 }}>
              <Stack align="center" gap="sm">
                <Image src={ChecklistMinimalistic} w={64} />
                <Text c="white" fz={rem(24)}>
                  Transparent Processes
                </Text>
                <Text c="gfdark.5" fz={rem(16)} align={'center'}>
                  Our methods are transparent, allowing you to understand how your contributions are allocated and how your art
                  holdings are managed. We provide comprehensive reports and updates, ensuring full visibility into the
                  performance of your art assets.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <Stack align="center" gap="sm">
                <Image src={ShieldCheck} w={64} />
                <Text c="white" fz={rem(24)}>
                  Secure Transactions
                </Text>
                <Text c="gfdark.5" fz={rem(16)} align={'center'}>
                  We prioritize the security of your contributions. Our platform utilizes robust security measures to safeguard
                  your personal and financial information. Additionally, our use of blockchain technology ensures secure and
                  tamper-proof ownership records for all artworks.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 4 }}>
              <Stack align="center" gap="sm">
                <Image src={UsersGroupTwoRounded} w={64} />
                <Text c="white" fz={rem(24)}>
                  Expert Oversight
                </Text>
                <Text c="gfdark.5" fz={rem(16)} align={'center'}>
                  Our team includes experienced professionals and experts in Islamic arts and finance. They conduct rigorous due
                  diligence on all artworks, ensuring that only high-quality and ethically sound artwork opportunities are
                  presented to our customers.
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>

          <Group mt={100}>
            <Button component={Link} href={user ? '/art-sales' : '/register'} size="md" variant="outline-gf" c="white">
              BUY NOW
            </Button>
          </Group>
        </Stack>
      </Container>
    </Box>
  );
}
